<template>
  <div class="py-32">
    <div class="flex justify-center items-center flex-col">
      <h2 class="text-3xl font-bold pb-12">Your Profile</h2>
      <div class="relative mb-6">
        <img src="@/assets/img/admin-avatar.svg" alt="avatar">
        <span class="absolute bottom-7 right-0 cursor-pointer">
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" fill="#48BFE3" stroke="white"/>
            <path d="M15.5 8.49991C15.6313 8.36859 15.7872 8.26441 15.9588 8.19334C16.1304 8.12227 16.3143 8.08569 16.5 8.08569C16.6857 8.08569 16.8696 8.12227 17.0412 8.19334C17.2128 8.26441 17.3687 8.36859 17.5 8.49991C17.6313 8.63123 17.7355 8.78713 17.8066 8.95871C17.8776 9.13029 17.9142 9.31419 17.9142 9.49991C17.9142 9.68562 17.8776 9.86952 17.8066 10.0411C17.7355 10.2127 17.6313 10.3686 17.5 10.4999L10.75 17.2499L8 17.9999L8.75 15.2499L15.5 8.49991Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
      </div>
      <h3 class="font-semibold text-xl lato">Cameron Williamson</h3>
      <div class="flex items-center mb-6">
        <p class="text-maingrey font-normal lato pr-1.5">cameronwilliamson@aol.com</p>
        <span class="cursor-pointer">
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width="16" height="16" rx="8" fill="#5E60CE"/>
            <path d="M10.6666 6.5L6.99992 10.1667L5.33325 8.5" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
      </div>
      <button class="bg-primary rounded-full py-3 px-6 text-white font-bold focus:outline-none w-52">Update Password</button>
      <div class="flex justify-between items-center w-7/12 mt-24">
        <p class="font-bold text-lg">Manage team members</p>
        <span class="text-blue text-sm uppercase cursor-pointer">Add team member</span>
      </div>
      <el-table :data="tableData" style="width:58.333333%" class="lato border-t border-gray-200 mt-6">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="name" label="Name"></el-table-column>
        <el-table-column prop="role" label="Role"></el-table-column>
        <el-table-column prop="date" label="Date Added"></el-table-column>
        <el-table-column label="Actions">
          <template slot-scope="scope">
            <span v-if="scope">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 7.5C12.8284 7.5 13.5 6.82843 13.5 6C13.5 5.17157 12.8284 4.5 12 4.5C11.1716 4.5 10.5 5.17157 10.5 6C10.5 6.82843 11.1716 7.5 12 7.5Z" fill="#9A9A9A"/>
                <path d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z" fill="#9A9A9A"/>
                <path d="M12 19.5C12.8284 19.5 13.5 18.8284 13.5 18C13.5 17.1716 12.8284 16.5 12 16.5C11.1716 16.5 10.5 17.1716 10.5 18C10.5 18.8284 11.1716 19.5 12 19.5Z" fill="#9A9A9A"/>
              </svg>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          name: "Conrad Andrews",
          role: "Admin",
          date: '22/08/2021'
        },
        {
          name: "Andrea Jones",
          role: "Member",
          date: '22/08/2021'
        },
        {
          name: "Cameron Williamson",
          role: "Member",
          date: '22/08/2021'
        }
      ]
    }
  }

}
</script>

<style>
.el-table thead {
  color: #292929;
  font-weight: 500;
}
</style>